import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from 'gatsby'

import { isLoggedIn, LoginPath } from '../services/auth'

import './layout.css'
import '../App.css'

const Layout = ({ children }) => {
  if (typeof window === 'undefined') return null
  if (!isLoggedIn(window)) {
    navigate(LoginPath)
    return null
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 0
            }}
          >
            <main>{children}</main>
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
