import React from 'react'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from '../services/auth'
import backgroundImg from '../images/year-one/main-background.png'

class Login extends React.Component {
  state = { password: `` }

  handleUpdate = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    handleLogin(this.state.password)
    navigate('/')
  }

  render() {
    if (isLoggedIn(window)) navigate('/')

    return (
      <div style={styles.page}>
        <div style={styles.passwordBox}>
          <h1 style={{ fontFamily: 'Dank Mono Italic' }}>
            Welcome to Judith Y Nikolas
          </h1>
          <p>Please enter the secret password to continue</p>
          <p>Hint: What do I call you</p>
          <form onSubmit={this.handleSubmit}>
            <input
              style={{ border: 0, verticalAlign: 'middle' }}
              type='password'
              name='password'
              placeholder='Password'
              onChange={this.handleUpdate}
            />
            <button type='submit' style={{ verticalAlign: 'middle' }}>
              Submit
            </button>
          </form>
        </div>
      </div>
    )
  }
}

const styles = {
  page: {
    background: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Dank Mono'
  },
  passwordBox: {
    padding: '24px 81px',
    textAlign: 'center',
    background: 'rgba(19, 51, 21, 0.5)'
  }
}

export default Login
