import React, { useState } from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import GoogleLogo from '../images/googlelogo.png'

const IndexPage = () => (
  <Layout>
    <div style={styles.container}>
      <img src={GoogleLogo} style={{ width: 272, height: 92 }} />
      <form>
        <div style={styles.searchBar}>
          <input style={styles.searchInput} defaultValue='how to be romantic' />
        </div>
        <div style={styles.buttonArea}>
          <LinkButton text='Search' to='/search' />
          <LinkButton text="I'm Feeling Lucky" to='/lucky' />
        </div>
      </form>
    </div>
  </Layout>
)

const LinkButton = ({ text, to }) => {
  const [hovered, setHover] = useState(false)
  const toggleHover = () => setHover(!hovered)
  const style = { ...styles.button }
  if (hovered) {
    style.color = '#222'
    style.border = '1px solid #c6c6c6'
    style.boxShadow = '0 1px 1px rgba(0,0,0,0.1)'
  }
  return (
    <span style={{ padding: 8 }}>
      <Link to={to}>
        <button
          style={style}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          {text}
        </button>
      </Link>
    </span>
  )
}

const styles = {
  container: {
    width: '100%',
    marginTop: 120,
    marginBottom: 90,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  searchBar: {
    background: '#fff',
    display: 'flex',
    borderRadius: 2,
    border: 'none',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08)',
    zIndex: 3,
    height: 44,
    margin: '0 auto',
    width: 484
  },
  searchInput: {
    display: 'flex',
    padding: '5px 8px 0 16px',
    border: 'none'
  },
  buttonArea: { paddingTop: 24, textAlign: 'center' },
  button: {
    height: 36,
    color: '#757575',
    border: '1px solid #f2f2f2',
    padding: '0 16px',
    fontSize: 13,
    fontWeight: 'bold',
    cursor: 'pointer'
  }
}

export default IndexPage
