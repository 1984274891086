import React from 'react'
import { navigate } from 'gatsby'

import { isLoggedIn, LoginPath } from '../services/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (typeof window === 'undefined') return null
  if (!isLoggedIn(window) && location.pathname !== LoginPath) {
    // If the user is not logged in, redirect to the login page.
    navigate(LoginPath)
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
