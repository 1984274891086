import React from 'react'
import { Router } from '@reach/router'

import Login from '../components/login'
import PrivateRoute from '../components/PrivateRoute'
import { LoginPath } from '../services/auth'
import IndexPage from '.'

const App = () => (
  <Router>
    <PrivateRoute path='/app' component={IndexPage} />
    <Login path={LoginPath} />
  </Router>
)

export default App
